jQuery(document).ready(function($){

	$('.slider').each(function(){
		var $slider = $(this).find('.slider__container');
		var settings = getSliderSettings($(this).attr('data-slider-object'));

		initSlider($slider,settings);
	});

	function initSlider(element,settings) {

		element.slick(settings);
	}

	function getSliderSettings(type) {
		var slider_settings = {};
		
		switch(type) {

			case 'photo':
				slider_settings = {
					autoplay: true,
					autoplaySpeed: 3000,
					infinite: true,
					speed: 525,
					slidesToShow: 1,
					slidesToScroll: 1,
					cssEase: 'ease-in-out',
					arrows: true,
					dots: true,
//					rows: 0,
				}
				break;

			case 'testimonial':
				slider_settings = {
					autoplay: true,
					autoplaySpeed: 3000,
					infinite: true,
					speed: 525,
					slidesToShow: 1,
					slidesToScroll: 1,
					cssEase: 'ease-in-out',
					arrows: false,
					dots: true,
//					rows: 0,
				}
				break;

			case 'quote':
			default:
				slider_settings = {
					autoplay: true,
					autoplaySpeed: 5000,
					infinite: true,
					speed: 525,
					slidesToShow: 1,
					slidesToScroll: 1,
					cssEase: 'ease-in-out',
					arrows: true,
					dots: false,
//					rows: 0,
					responsive: [
						{
						breakpoint: 1024,
						settings: {
							slidesToShow: 1,
								adaptiveHeight: true,
							}
						},
					]
				}
				break;
		}

		return slider_settings;
	}

});
