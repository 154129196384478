(function($){
    function initCustomLoadMore() {
        const loadOnClick = 9;

        $('a.loadMore:not(.load-more)').on('click', function(e){
            e.preventDefault();

            var $button = $(this);
			if( $button.hasClass( 'no-more' ) || $button.hasClass('disabled') ) return;

            var container = $button.attr('data-container');
            var showed = 0;
            
            $(container).children('.hentry').each(function(){
                if (showed >= loadOnClick) {
                    return;
                }

                if ($(this).css('display') === 'none') {
                    $(this).show(400);
                    showed++;
                }
            });

            var toShow = false;
            $(container).children('.hentry').each(function(){
                if ($(this).css('display') === 'none') {
                    toShow = true;
                    return;
                }
            });
            if (!toShow) {
                $button.addClass('no-more');
                $button.prop('disabled',true);
            }

			new Foundation.Equalizer( $(container) ).applyHeight();
			$(window).trigger('resize');
        });
    }

    $(document).ready(function(){
        initCustomLoadMore();
    });
})(jQuery);