jQuery(document).ready(function($){
	
	$('.js-toggle-menu').on('click', function() { $('body').toggleClass('menu-is-open'); });

	$('.js-toggle-menu-shelve').on('click', function() { $('body').toggleClass('menu-shelve-is-open'); $('li.menu-item-has-children').removeClass('clicked'); });

	$('.off-canvas__menu ul > li.menu-item-has-children a').on('click', function(e) {
		e.preventDefault();
		$(this).closest('li').addClass('clicked');
		showNavSubContent('populate',' .off-canvas__menu ul > li.menu-item-has-children.clicked > ul', $(this).text());
	});

	$('.off-canvas__menu .search a').on('click', function(e) {
		e.preventDefault();
		showNavSubContent('populate', '#search-modal', $(this).attr('title'));
	});

	$('.off-canvas__menu ul > li.locale a').on('click', function(e) {
		e.preventDefault();
		showNavSubContent('populate', '#country-modal', $(this).attr('title'));
	});

	$('.off-canvas__menu ul > li.lang a').on('click', function(e) {
		e.preventDefault();
		showNavSubContent('show', 'ul.langs', null);
	});

	$(window).on('open.zf.reveal', function() {
		if($('body').hasClass('menu-is-open')) {
			$('.reveal').each(function() {
				$(this).foundation('destroy');
			});
		}
	});

	function showNavSubContent(type, selector, title) {
		var shelveContent;

		if (!!document.querySelector('.js-shelve-content')) {
			document.querySelector('.js-shelve-content').innerHTML = '';
			shelveContent = document.querySelector('.js-shelve-content');
		} else {
			shelveContent = document.createElement('div');
			shelveContent.classList.add('js-shelve-content');
		}

		if(title !== null) { document.querySelector('.off-canvas__menu__shelve .off-canvas__menu__top-bar span').textContent = title; }

		switch(type) {

			case 'populate':
				shelveContent.innerHTML = document.querySelector(selector).outerHTML;
				break;

			case 'show':
				document.querySelector(selector).classList.toggle('langs-are-shown');
				break;

		}

		document.querySelector('.off-canvas__menu__shelve').appendChild(shelveContent);
		document.querySelector('body').classList.toggle('menu-shelve-is-open');
	}

});
