jQuery(document).ready(function($){

	var completed = 0;
	var show;

	function init(){

		if($('ul.loop').length){
			// console.log('on page with posts');
			show = $('ul.loop').data('show');
			// console.log('show ' + show);
			preloadPosts();
		}

	}

	function preloadPosts(){
		if(completed < show){
			completed++;
			// console.log(completed + '/' + show);
			preloadPost();
		}else{
			// console.log('max reached');
		}
		//preload the first n posts sim -> preloadPost(el at index completed)
		//return true after completion
	}

	function preloadPost(el){
		// console.log('preload post');
		var post = el;
		//load images etc.
		preloadPosts();
	}

	// init();

});
