jQuery(document).ready(function($){
	
	function playHeaderVideo(){
		$('#play-video').get(0).play();
	}
	
	function pauseHeaderVideo(){
		$('#play-video').get(0).pause();
	}
	
	function pauseModalVideo( modal ){
		var iframe = modal.find('iframe').get(0);
		var player = $f(iframe);
		
		player.api('pause');
	}

	function playModalVideo( modal ){
		var iframe = modal.find('iframe').get(0);
		var player = $f(iframe);
		
		player.api('play');
	}
	
	function playServiceArchiveVideo(button){
		
		var $pauseplay = $(button);
		var $target = $pauseplay.attr('data-video');
		
		if( Foundation.MediaQuery.atLeast('medium') ){
			
			$('#' + $target).foundation('open');
			
		} else{
			
			var $video = videos[$target];
			var $small_modal = $pauseplay.siblings('.small-modal');
			var $iframe = $small_modal.find('iframe');
			
			var player = $f( $iframe.get(0) );
			
			$iframe.show();
			player.api('play');
		}
	}
	
	function init(){
		
		if( $('#play-video').find('source').length && Foundation.MediaQuery.atLeast('medium') ){
			
			$( "#play-video" ).on( 'canplay', function() { 
				playHeaderVideo();
	        });
		}
		
		$('.post-type-archive-service a.pauseplay').on('click', function(e){
			e.preventDefault();
			playServiceArchiveVideo(this);
		});
	}

				
	$(document).on('DOMNodeInserted', function(e) {
		if ($(e.target).hasClass('flex-video')) {
			$(e.target).addClass('vimeo').addClass('widescreen');
		}
	});	
	
	$(document).on( 'open.zf.reveal', '.modal--video[data-reveal]', function(){
		playModalVideo( $(this) );
		pauseHeaderVideo();
	});
	
	$(document).on( 'closed.zf.reveal', '.modal--video[data-reveal]', function(){
		pauseModalVideo( $(this) );
		playHeaderVideo();
	});
	
	init();

});
