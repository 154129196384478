jQuery.loadScript = function (url, callback) {
    jQuery.ajax({
        url: url,
        dataType: 'script',
        success: callback,
        async: true
    });
};

jQuery(document).ready(function($){

//	console.log($language);

	if( $('.social-feed-container').length ){
		loadFeeds();
	}

	if( $('.instagram-pics').length ){

		var $image_container = $('.instagram-pics').parent().parent();
		var $images = $('.instagram-pics img');

		$images.each(function(){
			var $image = $(this);

			var $image_smaller_height = $image_container.height() >= $image.height();
			var $image_landscape = ( $image.width() - $image.height() ) >= 0;
			var $container_landscape = ( $image_container.width() - $image_container.height() ) >= 0;

			if( $image_landscape && !$container_landscape ){
				$image.addClass('scale-reverse');
			}else if( $image_landscape && $container_landscape ){
				if( $image_smaller_height ){
					$image.addClass('scale-reverse');
				}
			}
		});

		$('.instagram-pics').slick({
			autoplay: true,
			autoplaySpeed: 5000,
			infinite: true,
			speed: 525,
			slidesToShow: 1,
			slidesToScroll: 1,
			cssEase: 'ease-in-out',
			arrows: false,
			dots: false,
		});
	}

	function loadFeeds(){

		var $social_feeds_array = jQuery.parseJSON( $social_feeds );

//		var $logo = atob($social_feeds_array.facebook.logo);

		var $social_feeds_object = {
			length: 200,
	        template: "/wp-content/themes/cartamundi/social-feed-template.html",
		};

		if( $social_feeds_array.hasOwnProperty('facebook') ){

			$social_feeds_object.facebook = {
		        accounts: ['@' + $social_feeds_array.facebook.account, '#' + $social_feeds_array.facebook.account],
		        limit: $social_feeds_array.facebook.count,
		        access_token: $social_feeds_array.facebook.key + '|' + $social_feeds_array.facebook.secret,
		    };

		}

		if( $social_feeds_array.hasOwnProperty('twitter') ){

			$social_feeds_object.twitter = {
		        accounts: ['@' + $social_feeds_array.twitter.account],
		        limit: $social_feeds_array.twitter.count,
		        consumer_key: $social_feeds_array.twitter.key,
		        consumer_secret: $social_feeds_array.twitter.secret,
		    };

		}

		if ( $language !== 'en' ) {
			$.loadScript('/wp-content/themes/cartamundi/bower_components/moment/locale/' + $language + '.js', function(){
//				console.log('Loaded : ' + $language);
				renderFeeds( $social_feeds_object );
			});
		}else{
			renderFeeds( $social_feeds_object );
		}
	}

	function renderFeeds( $social_feeds_object ){
		$('.social-feed-container').socialfeed($social_feeds_object);
	}

});
