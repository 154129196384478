jQuery(document).ready(function($){

	var index = 1;
	var backspace_interval, textanimation_interval, words;

	var deletespeedbetween = [40,50];
	var typingspeedbetween = [70,100];

	var wait = 2000;

	function init(){
		if($('h2.home span.animate').length){
			words = $('span.animate').data('words');
			$('span.animate').removeAttr('data-words');
			setTimeout(function(){
				backspaceText();
			},wait);
		}
	}

	function backspaceText(){
		var i = 0;
		var str, strval, strlength, move;
		backspace_interval = setInterval(function(){

			str = $('span.animate');
			strval = $('span.animate').html();
			strlength = strval.length;

			strval = strval.substring(0,strlength - 1);
			str.html(strval);

			checkBackspaceDone(str.html());

		}, Math.floor(Math.random() * deletespeedbetween[1]) + deletespeedbetween[0]);

	}

	function checkBackspaceDone(data){
		if(data === ''){
			clearInterval(backspace_interval);
			typeText(index);
			if(index < (words.length - 1)){
				index++;
			}else{
				index = 0;
			}
		}
	}

	function typeText(data){
        setTimeout(function(){
			animateText(words[data]);
        },100);
	}

	function animateText(data){
		var goal = data;
		var textdata = data.split('');
		var textanimationindex = 0;
		var size = textdata.length - 1;
		textanimation_interval = setInterval(function(){
			var cur = $('span.animate').html();
			var add = cur += textdata[textanimationindex];
			$('span.animate').html(add);
			checkTextAnimationDone($('span.animate').html(),goal);
			textanimationindex++;
		}, Math.floor(Math.random() * typingspeedbetween[1]) + typingspeedbetween[0]);
	}

	function checkTextAnimationDone(data,goal){
		if(data === goal){
			clearInterval(textanimation_interval);
			setTimeout(function(){
				backspaceText();
			},wait);
		}
	}

	init();

});