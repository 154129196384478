jQuery(document).ready(function($){
	
	var ie = false;

	function init(){

		if(checkIE() === true){
			ie = true;
			$('.duotone-blend').addClass('ie');
		}
		
		// TODO: Where is this used?
//		if($('.privacy-discl').length){
//			$('.privacy-discl label.gfield_label').eq(0).html('<a href="/privacy" target="_blank">Privacy terms</a>');
//		}

	}

	function checkIE(){
		if (/MSIE 10/i.test(navigator.userAgent) || /MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent) || /Edge\/12./i.test(navigator.userAgent)) {
		   return true;
		}else{
			return false;
		}
	}

	init();

});
