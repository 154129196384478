jQuery(document).ready(function($){

	function init() {

		if($('.case-content').length){

			var waypoints_length = $('.case-content__nav li').length;

            $('.case-content__waypoint').each(function(){

            	var current_index = $(this).attr('data-waypoint-index');

                new Waypoint({
                    element: $(this),
                    handler: function(direction){
                    	updateCaseNav(current_index);
                    },
                    offset: '20%'
                });

                new Waypoint.Inview({
                    element: $(this),
                    enter: function(direction){
                        if(direction === 'up'){
                            updateCaseNav(current_index);
                        }
                    },
                    exited: function(direction){
                        if(direction === 'up'){
                            updateCaseNav(current_index - 1);
                        }
                    }
                });

            });

            new Waypoint.Inview({
                element: $('.page-header_case'),
                exited: function(direction){
                    if(direction === 'down'){
                        makeCaseNavFixed();
                    }
                },
                enter: function(direction){
                    if(direction === 'up'){
                        makeCaseNavAbsolute(0);
                    }
                }
            });

            new Waypoint.Inview({
                element: $('.case-content__waypoint[data-waypoint-index=' + (waypoints_length - 1) + ']'),
                exit: function(direction){
                    if(direction === 'down'){
                        makeCaseNavAbsolute($('.case-content__waypoint[data-waypoint-index=' + (waypoints_length - 1) + ']').offset().top - $('.case-content').offset().top);
                    }
                },
                entered: function(direction){
                    if(direction === 'up'){
                       makeCaseNavFixed();
                    }
                }
            });
            
        }

//        if ($('.js-search-result-count').length) {
//			var results = $('.js-search-result').length;
//			$('.js-search-result-count').text('(' + results + ')');
//		}
		
		if($("[data-object-fit='cover'], .scale-images").length){
			objectFitImages();
		}
        
	}
	
	function makeCaseNavFixed() {
		var top = '35px';
		if(Foundation.MediaQuery.atLeast('large')){ top = '50px'; }
		$('.case-content__nav').css({
			'position': 'fixed',
			'top': top,
		});
	}

	function makeCaseNavAbsolute(pos) {
		$('.case-content__nav').css({
			'position': 'absolute',
			'top': pos + 'px',
		});
	}

	function navigateCase(index) {
		$('html, body').animate({
			scrollTop: $('.case-content__waypoint[data-waypoint-index=' + index + ']').offset().top - 36
		}, 1000);
	}

	function updateCaseNav(index) {
		var current_index = Number(index) + 1;
		if(current_index < 10) { current_index = "0" + current_index; }
		$('.case-content__nav .nav__count').text(current_index);
		$('.case-content__nav .is-active').removeClass('is-active');
		$('.case-content__nav li').eq(index).addClass('is-active');
	}

	function checkCaseCards() {
		$('.card--case').each(function() {
			if($(this).find('.card__info h3').length) {
				placeCardText($(this));
			}
		});
	}

	function resetCaseCardsTop() {
		$('.card--case').each(function() {
			if($(this).find('.card__info h3').length) {
				var $card_info = $(this).find('.card__info');
				$card_info.css('top','unset');
			}
		});
	}

	// Inital card__info position
	function placeCardText(el) {
		var card_height = el.outerHeight();
		var $card_info = el.find('.card__info');
		var title_height = $card_info.find('h3').outerHeight();
		$card_info.css('top',(card_height - title_height - 13) + 'px');
	}

	function showSwitcherooData(data) {
		hideAllSwitcherooData();
		$('.switcheroo__data--' + data).show();
		$(window).trigger('resize');
	}

	function hideAllSwitcherooData() {
		$('.switcheroo__data').each(function() {
			$(this).hide();
		});
	}

	$(document).on('click', '.feedback--mobile', function(){
		if( !Foundation.MediaQuery.atLeast('medium') ){
			
			var clicked = $(this);
			
			if( !clicked.hasClass('is-open') ){
				clicked.addClass('is-open');
			}else{
				clicked.removeClass('is-open');
			}
			
			if($('.feedback--mobile div.form').data('userinfo').length){
				var userinfo = $('.feedback--mobile div.form').data('userinfo');
				$('.feedback--mobile').removeAttr('data-userinfo');
				$('.feedback--mobile div.form .user-info input').val(userinfo);
			}
			
		}
	});

	$(document).on('click', '.feedback--desktop', function(){
		if( Foundation.MediaQuery.atLeast('medium') ){
			
			if($('.feedback--desktop div.form').data('userinfo').length){
				var userinfo = $('.feedback--desktop div.form').data('userinfo');
				$('.feedback--desktop').removeAttr('data-userinfo');
				$('.feedback--desktop div.form .user-info input').val(userinfo);
			}
			
		}
	});

	$(document).on('click', 'a.open-search-box', function(){
		$('#search-modal input.search-field').focus();
//		setTimeout(function(){
//			$("#search-modal input.search-field").focus();
//		}, 0);
	});
	
	$(document).on('click', '.tab-trigger', function(e){
		e.preventDefault();
		$('li.active').removeClass('active');
		$(this).closest('li').addClass('active');
		showTechSpecsByTabId( $(this).data('tab-id') );
	});
	
	$('.case-content__nav li').on('click', function() {
		navigateCase($(this).index());
	});

	$('.switcheroo__nav button').on('click', function() {
		if (!$(this).hasClass('is-active')) {
			$('.switcheroo__nav .is-active').removeClass('is-active');
			showSwitcherooData($(this).attr('data-switcheroo-trigger'));
			$(this).addClass('is-active');
		}
	});

	$('.dropdown-filter__trigger').on('click', function() {
		$(this).next('.dropdown-filter__list').toggleClass('is-open');
	});

	if($('.filter-bar').length){
		$(document).on('click', '.filter-bar .is-active', function(e){
			e.preventDefault();

			$('.filter-bar').toggleClass('is-open');
		});
	}

	// Card hover translation
	$(document).on({
		mouseenter: function() {
			var $card_info = $(this).find('.card__info');
			if($card_info.find('p').length && $(window).width() > 1024) {
				var p_height = $card_info.find('p').outerHeight();
				$(this).find('.card__info').css({
					'-webkit-transform': 'translateY(-' + (p_height + 22) + 'px)',
					'-moz-transform': 'translateY(-' + (p_height + 22) + 'px)',
					'-o-transform': 'translateY(-' + (p_height + 22) + 'px)',
					'-ms-transform': 'translateY(-' + (p_height + 22) + 'px)',
					'transform': 'translateY(-' + (p_height + 22) + 'px)',
				});
			}
		},
		mouseleave: function() {
			$(this).find('.card__info').css({
				'-webkit-transform': 'translateY(0)',
				'-moz-transform': 'translateY(0)',
				'-o-transform': 'translateY(0)',
				'-ms-transform': 'translateY(0)',
				'transform': 'translateY(0)',
			});
		},
	}, '.card--case');
	
	$(document).on('click', ".page-template-page-home-one-pager .header__main .header__nav .menu-item a", function(e) {
		var destination = $(this).attr('href');
		
		if ( destination.indexOf('#') !== -1 ) {
			e.preventDefault();
			
			destination = destination.replace('/' + $language + '/','');
			
		    $('html, body').animate({
		        scrollTop: $(destination).offset().top
		    }, 800);
		}
	}); 
	
	$(document).on('load-more', ".home-one-pager .cases .loop", function(e) {
		if($('.card--case').length && $(window).width() >= 640) {
			checkCaseCards();
		}else if($('.card--case').length) {
			resetCaseCardsTop();
		}
	});
	
	$(document).on('load-more', ".search-results__list", function(e) {
		var elem = new Foundation.Equalizer( $( "div[data-equalizer]" ) ); 
        elem.applyHeight();
	});

	$(window).on('load resize', function() { 
		if($('.card--case').length && $(window).width() >= 640) {
			checkCaseCards();
		}else if($('.card--case').length) {
			resetCaseCardsTop();
		}

		
		if($("[data-object-fit='cover'], .scale-images").length){
			objectFitImages();
		}
	});

	$(window).on('resize', function() { 
		if($('.card--case').length && $(window).width() >= 640) {
			checkCaseCards();
		}else if($('.card--case').length) {
			resetCaseCardsTop();
		}
		
		if($("[data-object-fit='cover'], .scale-images").length){
			objectFitImages();
		}
	});

	window.onload = function() { init(); }
});
