jQuery(document).ready(function($){

	// TODO: Replace old Woody code with completely new code PLZ!

	/*
	 * Variables
	 */
	var markersonmap = [], markers = [], filtermarkersonmap = [];
	var style;
	var continent_dropdown_showing = false;
	var country_dropdown_showing = false;
	var active_continent = 'all', active_country = 'all';
	var map;
	var active_locations = 0;
	var content = null, infowindow;
	var current_location;

	var all = [];

	var all_countries = null;
	var index_count = 0;
	var completion = 0;

	var clean_email;

	/*
	 * Functions
	 */
	function listAllCountries(array){
		for (var i = 0; i < array.length; i++){
			all.push(array[i]);
		}
	}

	function showContinentDropdown(data){
		var list = data;
		hideCountryDropdowns();
		if($('ul[data-show="'+list+'"]').hasClass('active')){
			$('ul[data-show="'+list+'"]').hide();
			$('ul[data-show="'+list+'"]').removeClass('active');
		}else{
			$('ul[data-show="'+list+'"]').show();
			$('ul[data-show="'+list+'"]').addClass('active');
		}
	}

	function showCountryDropdown(data){
		var list = data;
		hideContinentDropdowns();
		if($('ul[data-show="'+list+'"]').hasClass('active')){
			$('ul[data-show="'+list+'"]').hide();
			$('ul[data-show="'+list+'"]').removeClass('active');
		}else{
			$('ul[data-show="'+list+'"]').show();
			$('ul[data-show="'+list+'"]').addClass('active');
		}
	}

	function checkDone(){
		if($('div.addresses-container .map-location.hit').length == filtermarkersonmap.length){
			fitMapFilter();
		}
	}

	function populateCountriesByContinent(){
		$('ul.country-list li').each(function(){
			var self = $(this);
			if(!self.hasClass('all-countries')){self.remove();}
		});
		$('ul.country-list li.all-countries').addClass('active');

		if(active_continent == 'all'){

			$.each(all_countries, function(key,value) {
				populateCountryList(value);
			});

		}
		else{
			populateCountryList(all_countries[active_continent]);
		}


		fitMapFilter();
	}

	function populateCountryList(countries){

		$.each(countries, function(key,value) {

			$('ul.country-list').append(
				'<li>'+
				'<a class="country-link" data-filter-country="'+key+'">'+
				value+
				'</a>'+
				'</li>'
			);

		});

	}

	function filterLocations(continent,country){

//		console.log(continent + ' ' + country);

		filtermarkersonmap = [];

		completion = 0;
		$('div.load-bar').css('width',0 + '%');

		$('.load-map').css('opacity','0');
		$('p.loading').show();
		$('div.load-bar').show();

		continent = typeof continent !== 'undefined' ? continent : 'all';
		country = typeof country !== 'undefined' ? country : 'all';

		var filter_continent = continent;
		var filter_country = country;

		if(filter_continent !== 'all'){
			$('div.addresses-container .map-location').each(function(){
				var self = $(this);
				var continent = self.data('continent');
				if(continent !== filter_continent){
					self.hide();
					self.removeClass('hit');
				}else{
					self.fadeIn();
					self.addClass('hit');
				}
			});
		}else{
			$('div.addresses-container .map-location').each(function(){
				var self = $(this);
				self.fadeIn();
				self.addClass('hit');
			});
		}

		if(filter_country !== 'all'){
			$('div.addresses-container .map-location.hit').each(function(){
				var self = $(this);
				var country = self.data('country');

				if(country !== filter_country){
					self.hide();
					self.removeClass('hit');
				}
			});
		}
		else{
			$('div.addresses-container .map-location').each(function(){
				var self = $(this);
				var continent = self.data('continent');
				if(continent == active_continent){
					self.fadeIn();
					self.addClass('hit');
				}
			});
		}

		markersonmap = [];
		checkAddressLists();

		clearMarkers();
		addLocationsToMap();
	}

	function hideContinentDropdowns(){
		$('.dropdown-filter ul.continent-list').each(function(){
			$(this).removeClass('active');
			$(this).hide();
		});
	}

	function hideCountryDropdowns(){
		$('.dropdown-filter ul.country-list').each(function(){
			$(this).removeClass('active');
			$(this).hide();
		});
	}

	function checkAddressLists(){
		$('.addresses-container div.columns.addresses').each(function(){
			var $self = $(this);
			var hits = 0;
			$self.find('div.map-location.hit').each(function(){ hits++; });
			if(hits === 0){
				$self.find('p.no-results').fadeIn();
			}else{
				$self.find('p.no-results').hide();
			}
		});
	}

	function addLocationsToMap(){

		var max_locations = $('div.addresses-container .map-location.hit').length;

		active_hits = 0;
		var self = $('div.addresses-container .map-location.hit').eq(index_count);
		var address = self.data('address');
		var latitude = self.data('latitude');
		var longitude = self.data('longitude');

		putAddress(address,latitude,longitude,self);
		active_hits++;

		if(index_count < (max_locations - 1)){
			index_count++;
			addLocationsToMap();
		}else{
			index_count = 0;
			$('.load-map').css('opacity',1);
			$('p.loading').hide();
			$('div.load-bar').hide();
			fitMap();
		}
	}

	function putAddress(address,latitude,longitude,el) {
		var codeaddress = address;

		if(latitude && longitude){
			var LatLng = {lat: latitude, lng: longitude};

			map.setCenter(LatLng);
			var iconBase = '/wp-content/themes/cartamundi/dist/images/cm-marker.png';
			var marker = new google.maps.Marker({
				map: map,
				position: LatLng,
				icon: iconBase
			});
			var markerlatlng = new google.maps.LatLng(latitude,longitude);

			var infowindow;

			google.maps.event.addListener(marker, 'click', function() {
				if (infowindow) {
			        infowindow.close();
			    }
			    $('p.scroll-down').fadeOut();
			    var title = el.find('h4').html();
			    var location = el.find('h5').html();
			    var theaddress = el.find('address').html();
			    var telephone = el.find('p.telephone').html();
				var fax = el.find('p.fax').html();
				var company_number = el.find('p.company-number').html();
				var website = el.find('a.website-link').attr('href');
				var email = el.find('a.email-link').attr('href');
				if(email !== undefined){
					clean_email = email.replace('mailto:','');
				}
				content = '<div class="info-window" style="width:240px;z-index:59;position:relative;">' +
				'<h4 style="padding: 0; margin: 0; color: #e41d36; font-size: 1.1rem;">' + title + '</h4>' +
				'<h5 style="padding: 0; margin: 0; color: #4d4848; font-size: 1.1rem; padding-bottom: 1rem;">' + location + '</h5>' +
				'<address style="color: #7e7673; padding: 0; margin: 0; font-style: normal; font-size: 1rem;">' + theaddress + '</address>' +
				'<p style="color: #7e7673; padding: 0; margin: 0; font-style: normal; font-size: 1rem;">tel. ' + telephone + '</p>' +
				'<p style="color: #7e7673; padding: 0; margin: 0; font-style: normal; font-size: 1rem;">fax ' + fax + '</p>' +
				'<p style="color: #7e7673; padding: 0; margin: 0; font-style: normal; font-size: 1rem; padding-top: 1rem;">' + company_number + '</p>' +
				'<div class="link" style="margin-top: 1rem;">';
				if(website !== undefined){
					content += '<a style="font-size:0.9rem;" class="website-link" href="' + website + '" target="_blank">' + website + '</a><br/>';
				}
				if(clean_email !== undefined){
					content += '<a style="margin-top: 0.2rem; font-size:0.9rem;" class="email-link" href="' + email + '" target="_blank">' + clean_email + '</a>';
				}
				content +=  '</div>' + '</div>';
				infowindow = new google.maps.InfoWindow({ content: content });
				infowindow.open(map,marker);
				google.maps.event.addListener(infowindow,'closeclick',function(){
					$('p.scroll-down').fadeIn();
				});
			});

			markers.push(marker);
			markersonmap.push(markerlatlng);
			filtermarkersonmap.push(markerlatlng);
			checkDone();

			completion++;
			var top = $('div.addresses-container .map-location.hit').length;

			var percentage = Math.round((completion / top) * 100);

			$('p.loading span').html(percentage);
			$('div.load-bar').css('width',percentage + '%');

		}

	}

	function fitMap(){
		var latlngbounds = new google.maps.LatLngBounds();
		for (var i = 0; i < markersonmap.length; i++) {
		    latlngbounds.extend(markersonmap[i]);
		}
		map.fitBounds(latlngbounds);
	}

	function fitMapFilter(){
		var latlngbounds = new google.maps.LatLngBounds();
		for (var i = 0; i < markersonmap.length; i++) {
		    latlngbounds.extend(filtermarkersonmap[i]);
		}
		map.fitBounds(latlngbounds);
	}

	function clearMarkers() {
  		setMapOnAll(null);
  		markers = [];
  		markersonmap = [];
	}

	function setMapOnAll(map) {
		for (var i = 0; i < markers.length; i++) {
			markers[i].setMap(map);
		}
	}

	function render_map($el,latlng) {
		var $markers = $el.find('.marker');
		var args = {
			zoom		: 16,
			center		: latlng,
			mapTypeId	: google.maps.MapTypeId.ROADMAP,
			styles		: style,
			disableDefaultUI: true
		};

		map = new google.maps.Map( $el[0], args);
	}

	function init(){

		if( $('#map').length ){

			all_countries = $('ul.country-list').data('countries');

			$.each(all_countries, function(key,value) {
				listAllCountries(value);
			});

			var	main_color = '#7e7673',
			blue = '#f3efee',
			saturation_value = -40,
			brightness_value = 10;

			style = [
				{
					//set saturation for the labels on the map
					elementType: "labels",
					stylers: [
						{saturation: saturation_value}
					]
				},
			    {	//poi stands for point of interest - don't show these lables on the map
					featureType: "poi",
					elementType: "labels",
					stylers: [
						{visibility: "off"}
					]
				},
				{
					//don't show highways lables on the map
			        featureType: 'road.highway',
			        elementType: 'labels',
			        stylers: [
			            {visibility: "off"}
			        ]
			    },
				{
					//don't show local road lables on the map
					featureType: "road.local",
					elementType: "labels.icon",
					stylers: [
						{visibility: "off"}
					]
				},
				{
					//don't show arterial road lables on the map
					featureType: "road.arterial",
					elementType: "labels.icon",
					stylers: [
						{visibility: "off"}
					]
				},
				{
					//don't show road lables on the map
					featureType: "road",
					elementType: "geometry.stroke",
					stylers: [
						{visibility: "off"}
					]
				},
				//style different elements on the map
				{
					featureType: "transit",
					elementType: "geometry.fill",
					stylers: [
						{ hue: main_color },
						{ visibility: "on" },
						{ lightness: brightness_value },
						{ saturation: saturation_value }
					]
				},
				{
					featureType: "poi",
					elementType: "geometry.fill",
					stylers: [
						{ hue: main_color },
						{ visibility: "off" },
						{ lightness: brightness_value },
						{ saturation: saturation_value }
					]
				},
				{
					featureType: "poi.government",
					elementType: "geometry.fill",
					stylers: [
						{ hue: main_color },
						{ visibility: "off" },
						{ lightness: brightness_value },
						{ saturation: saturation_value }
					]
				},
				{
					featureType: "poi.sport_complex",
					elementType: "geometry.fill",
					stylers: [
						{ hue: main_color },
						{ visibility: "off" },
						{ lightness: brightness_value },
						{ saturation: saturation_value }
					]
				},
				{
					featureType: "poi.attraction",
					elementType: "geometry.fill",
					stylers: [
						{ hue: main_color },
						{ visibility: "off" },
						{ lightness: brightness_value },
						{ saturation: saturation_value }
					]
				},
				{
					featureType: "poi.business",
					elementType: "geometry.fill",
					stylers: [
						{ hue: main_color },
						{ visibility: "off" },
						{ lightness: brightness_value },
						{ saturation: saturation_value }
					]
				},
				{
					featureType: "transit",
					elementType: "geometry.fill",
					stylers: [
						{ hue: main_color },
						{ visibility: "on" },
						{ lightness: brightness_value },
						{ saturation: saturation_value }
					]
				},
				{
					featureType: "transit.station",
					elementType: "geometry.fill",
					stylers: [
						{ hue: main_color },
						{ visibility: "on" },
						{ lightness: brightness_value },
						{ saturation: saturation_value }
					]
				},
				{
					featureType: "landscape",
					stylers: [
						{ hue: main_color },
						{ visibility: "on" },
						{ lightness: brightness_value },
						{ saturation: saturation_value }
					]

				},
				{
					featureType: "road",
					elementType: "geometry.fill",
					stylers: [
						{ hue: main_color },
						{ visibility: "on" },
						{ lightness: brightness_value },
						{ saturation: saturation_value }
					]
				},
				{
					featureType: "road.highway",
					elementType: "geometry.fill",
					stylers: [
						{ hue: main_color },
						{ visibility: "on" },
						{ lightness: brightness_value },
						{ saturation: saturation_value }
					]
				},
				{
					featureType: "water",
					elementType: "geometry",
					stylers: [
						{ hue: main_color },
						{ visibility: "on" },
						{ lightness: 50 },
						{ saturation: -255 }
					]
				}
			];

			// README : Intention to deactivate map on mobile -> Needed?
//			if( Foundation.MediaQuery.atLeast('medium') ){

				$('#map').addClass('scrolloff');

				$('.map-container').on('click', function () {
					$('#map').removeClass('scrolloff');
					hideCountryDropdowns();
					hideContinentDropdowns();
				});

				$("#map").mouseleave(function () {
					$('#map').addClass('scrolloff');
				});

				geocoder = new google.maps.Geocoder();
				var latlng = new google.maps.LatLng(-34.397, 150.644);
				render_map($('#map'), latlng);
				addLocationsToMap();

//			}

			$('.dropdown-filter button.continent-button').on('click', function(e){
				e.preventDefault();
				var clicked = $(this);
				showContinentDropdown(clicked.data('show-trigger'));
			});

			$('.dropdown-filter button.country-button').on('click', function(e){
				e.preventDefault();
				var clicked = $(this);
				showCountryDropdown(clicked.data('show-trigger'));
			});
		}

	}

	/*
	 * Events
	 */

	$(document).on('click', 'ul.continent-list a', function(e){
		e.preventDefault();
		var clicked = $(this);
		$('button.continent-button').html(clicked.html());
		$('ul.continent-list li').each(function(){$(this).removeClass('active');});
		clicked.closest('li').addClass('active');
		var continent = clicked.data('filter-continent');

		active_continent = continent;
		active_country = 'all';
		$('button.country-button').html('All');

		filterLocations(continent,active_country);

		populateCountriesByContinent();
		hideContinentDropdowns();
	});

	$(document).on('click', '.country-link' ,function(e){
		e.preventDefault();
		var clicked = $(this);
		$('button.country-button').html(clicked.html());
		$('ul.country-list li').each(function(){$(this).removeClass('active');});
		clicked.closest('li').addClass('active');
		var country = clicked.data('filter-country');
		active_country = country;

		filterLocations(active_continent,country);

		hideCountryDropdowns();
	});

	init();

});
